<template>
  <Stores />
</template>

<script>
import Stores from "../components/Stores/Stores.vue"
export default {
  components:{
    Stores
  }
}
</script>

<style>

</style>