<template>
   <v-dialog v-model="dialog" max-width="600px">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-card :loading="isloading">
         <v-card-title>{{title}}</v-card-title>
         <v-card-text>
            <form>
               <v-text-field
                  v-model="nombre"
                  label="Nombre"
                  :error-messages="nombreErrors"
                  required
                  @input="$v.nombre.$touch()"
                  @blur="$v.nombre.$touch()"
               ></v-text-field>
            </form>
         </v-card-text>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">Cancelar</v-btn>
            <v-btn :loading="isloading" color="primary" @click="save">Guardar</v-btn>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";

export default {
   mixins: [validationMixin],
   validations: {
      nombre: {
         required,
         maxLength: maxLength(30),
         minLength: minLength(5),
      },
   },
   data: function () {
      return {
         sucursal_id: "",
         nombre: "",
         dialog: false,
         isloading: false,
      };
   },
   watch: {
      dialog: function (val) {
         if (!val) {
            this.close();
            this.$emit("close");
         }
      },
   },
   methods: {
      save: async function () {
         try {
            this.$v.$touch();
            if (!this.$v.$invalid) {
               this.isloading = true;
               let res = null;
               if (!this.isEdit) {
                  res = await this.$store.dispatch("insertSucursal", {
                     nombre: this.nombre,
                  });
               } else {
                  res = await this.$store.dispatch("updateSucursal", {
                     sucursal_id: this.sucursal_id,
                     nombre: this.nombre,
                  });
               }
               if (res == true) {
                  this.$emit("save");
                  this.close();
               } else {
                  throw res;
               }
            } else {
               throw "error";
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      close: function () {
         this.dialog = false;
         this.$v.$reset();
      },
      show: function (i) {
         this.dialog = true;
         this.sucursal_id = i.sucursal_id;
         this.nombre = i.nombre;
      },
   },
   computed: {
      isEdit() {
         if (this.sucursal_id) {
            return true;
         } else {
            return false;
         }
      },
      title(){
         if (this.sucursal_id) {
            return "Editar sucursal";
         } else {
            return "Crear sucursal";
         }
      },
      nombreErrors() {
         const errors = [];
         if (!this.$v.nombre.$dirty) return errors;
         !this.$v.nombre.required && errors.push("El nombre es requerido");
         !this.$v.nombre.maxLength && errors.push("Debe contener maximo 30 caracteres");
         !this.$v.nombre.minLength && errors.push("Debe contener minimo 5 caracteres");
         return errors;
      },
   },
};
</script>
